/* eslint-disable @typescript-eslint/camelcase */
import axios from "axios";
import { toISODateString } from "./helper";
import {
  ALERT_LEVEL,
  EventCenterData,
  EventCenterResponse,
  IoTHubAAA
} from "./type";

let heartbeatHandle: NodeJS.Timeout;
export interface AlertResponse {
  notificationCount: number;
  warningCount: number;
  alertCount: number;
  events: IoTHubAAA[];
  message?: string;
}

export const GenerateAlertDetail = (): IoTHubAAA => ({
  _event_id: "",
  sensorID: "Default Sensor ID",
  sensorDisplayName: "Default Sensor Display Name",
  sensorType: "[EMPTY]",
  message: `NULL`,
  timestamp: "00/00/0000 00:00:00",
  photoURLs: [],
  alertType: "Default Alert Type",
  level:
    Math.random() > 0.5
      ? ALERT_LEVEL.ALERT
      : Math.random() > 0.5
      ? ALERT_LEVEL.WARNING
      : ALERT_LEVEL.NOTIFICATION,
  highlight: Math.random() > 0.5 ? false : true,
  reported: Math.random() > 0.5 ? false : true,
  complete: Math.random() > 0.5 ? false : true,
  location: {
    latitude: 0,
    longitude: 0,
    altitude: 0
  }
});

export const GetAlertData = async (config: {
  alertLevel?: ALERT_LEVEL;
  offset?: number;
  projectId?: number;
}): Promise<AlertResponse | null> => {
  const date = new Date();
  const today = toISODateString(date);
  const resp: EventCenterResponse = await axios
    .get(process.env.VUE_APP_API_URL + "/events", {
      params: {
        labels: {
          ProjectId: [config.projectId?.toString()],
          DBView: ["vw_iothub_aaa"],
          // Env: [process.env.NODE_ENV]
          Env: ["production"]
        },
        offset: config.offset || 0,
        limit: 10000,
        start: today,
        sort: "event_time desc",
        show_handled: false
      },
      headers: {
        "user-Id": process.env.VUE_APP_USER_ID
      }
    })
    .then(ret => {
      if (ret.status === 200 && ret.data) {
        return ret.data;
      }
    })
    .catch(function(error) {
      console.error(error);
      return error.toJSON();
    });

  let [notificationCnt, warningCnt, alertCnt] = [0, 0, 0];

  if (!resp.data) {
    return null;
  }

  const events: IoTHubAAA[] = resp.data.map((r: EventCenterData) => {
    r.message._event_id = r.id;
    r.message.message = `[${r.message.sensorDisplayName}] - ${r.message.message}`;
    switch (r.message.level) {
      case ALERT_LEVEL.NOTIFICATION:
case ALERT_LEVEL.ACTION:
        r.message.level = ALERT_LEVEL.NOTIFICATION;
        notificationCnt++;
        break;
      case ALERT_LEVEL.WARNING:
case ALERT_LEVEL.ALARM:
        r.message.level = ALERT_LEVEL.WARNING;
        warningCnt++;
        break;
      case ALERT_LEVEL.ALERT:
              alertCnt++;
        break;
      default:
        break;
    }
    return r.message;
  });

  const res: AlertResponse = {
    notificationCount: notificationCnt,
    warningCount: warningCnt,
    alertCount: alertCnt,
    events: events,
    message: "Testing"
  };
  return res;
};

export const CompleteAlert = async (id: string): Promise<boolean> => {
  const url = process.env.VUE_APP_API_URL + `/events/${id}`;
  return await axios
    .delete(url, {
      headers: {
        "user-Id": process.env.VUE_APP_USER_ID
      }
    })
    .then(ret => {
      console.log(ret);
      return true;
    })
    .catch(err => {
      console.error(err);
      return false;
    });
};

export const FalseAlarm = async (id: string): Promise<boolean> => {
  const url = process.env.VUE_APP_API_URL + `/events/${id}/report-false-alarm`;
  return await axios
    .post(url, null, {
      headers: {
        "user-Id": process.env.VUE_APP_USER_ID
      }
    })
    .then(ret => {
      console.log(ret);
      return true;
    })
    .catch(err => {
      console.error(err);
      return false;
    });
};

export const AcknowledgeAlert = async (id: string): Promise<boolean> => {
  const url = process.env.VUE_APP_API_URL + `/events/${id}/acknowledged`;
  return await axios
    .post(url, null, {
      headers: {
        "user-Id": process.env.VUE_APP_USER_ID
      }
    })
    .then(ret => {
      console.log(ret);
      return true;
    })
    .catch(err => {
      console.error(err);
      return false;
    });
};

const SendEmptyMsg = (ws: WebSocket) => {
  console.log("===> SendEmptyMsg to web socket");
  ws.send("");
};

export const initWebSocket = async (
  projectId: number,
  onMessage: (data: IoTHubAAA) => void
): Promise<WebSocket> => {
  console.log("=Init Web Socket=");

  return new Promise(res => {
    const ws = new WebSocket(process.env.VUE_APP_WEBSOCKET_URL || "");
    ws.binaryType = "arraybuffer";

    ws.onerror = err => {
      console.error("Error in websocket connection: ", err);
      console.error((err as ErrorEvent).message);
      ws.close();
    };
    ws.onopen = () => {
      console.log("Web Socket: connection opens");
      const msg = {
        user_id: process.env.VUE_APP_USER_ID,
        labels: {
          ProjectId: [projectId.toString()],
          DBView: ["vw_iothub_aaa"],
          Env: [process.env.NODE_ENV]
        }
      };
      ws.send(JSON.stringify(msg));
      heartbeatHandle = setInterval(() => SendEmptyMsg(ws), 10000);
      console.log("Web Socket: heartbeat set, handle=" + heartbeatHandle);
      res(ws);
    };
    ws.onclose = function(e) {
      console.log(
        "Socket is closed. Reconnect will be attempted in 3 second.",
        e.reason
      );
      if (heartbeatHandle) {
        clearInterval(heartbeatHandle);
      }
      setTimeout(function() {
        res(initWebSocket(projectId, onMessage));
      }, 3000);
    };
    ws.onmessage = event => {
      if (event.data) {
        let data: EventCenterData = JSON.parse(
          Buffer.from(event.data).toString("utf-8")
        );
        if (typeof data !== "object") {
          console.warn("Web Socket: parsed data is not an object");
          data = JSON.parse(data);
        }
        console.log(
          "Web Socket: message received -",
          data,
          `type=${typeof data}`
        );
        data.message["_event_id"] = data.id;

        switch (data.message.level) {
          case ALERT_LEVEL.NOTIFICATION:
          case ALERT_LEVEL.ACTION:
            data.message.level = ALERT_LEVEL.NOTIFICATION;
            break;
          case ALERT_LEVEL.WARNING:
          case ALERT_LEVEL.ALARM:
            data.message.level = ALERT_LEVEL.WARNING;
            break;
          case ALERT_LEVEL.ALERT:
            break;
          default:
            break;
        }

        data.message.message = `[${data.message.sensorDisplayName}] - ${data.message.message}`;
        onMessage(data.message);
      }
    };
  });
};
